@import 'variables';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: 'KarnaphuliMJ Regular';
  src: url('../assets/fonts/KarnaphuliMJRegular.ttf') format('truetype');
  /* You can also provide additional font formats for better cross-browser compatibility */
}

// !pagination

.page-item .page-link {
  text-align: center;
  color: #f66600 !important;
  text-decoration: none;
}

.page-item a.page-link:hover {
  background-color: #cccccc;
}
.page-item a.page-link:focus {
  box-shadow: none;
}

.page-item.active .page-link {
  font-weight: 600;
  color: #ccc !important;
  background-color: #f66600 !important;
  outline: none;
}

.page-item.disabled .page-link {
  color: #6c757d !important;
  pointer-events: none;
  cursor: auto;
}

a {
  text-decoration: none;
}

.react-datepicker-wrapper {
  width: 100%;
}

.icon-hover:hover {
  background-color: #efefef;
  box-shadow: 0 0.15rem 0.35rem rgba(0, 0, 0, 0.08);
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding-top: 1.1rem !important;
  padding-left: 0.5rem !important;
  // padding-right: 1.1rem !important;
  // margin-right: 1.1rem !important;
}
.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 6px 30px !important;
}

.bg-red {
  background-color: red !important;
}
.bg-green {
  background-color: green !important;
}

.center-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Optional: Set a specific height to center vertically within the viewport */
}

.delete_btn_hover {
  &:hover {
    color: '#dc3545';
  }
}

.btn {
  font-size: 16px;
  &:hover {
    color: #fff;
  }
}

.edit_btn_hover {
  &:hover {
    color: '#198754';
  }
}

.cursor-pointer {
  cursor: pointer;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #f26133 !important;
}

.highlightOption,
.highlight,
.multiSelectContainer li:hover {
  background: #f26133 !important;
}

.editor_container {
  background-color: white;
  padding: 5px 8px;
  border: 1px solid #b1b1b1;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #454d57;
}

.invoice {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.invoice h2 {
  font-size: 12px;
  font-weight: 600;
}
.info {
  width: 50%;
  column-gap: 8px;
}
.spacing {
  width: 100%;
  height: 100%;
  margin-top: 50px !important;
}
.form-control {
  border-radius: 5px;
  border-color: #e3e1e1 !important;
}

//! Fahim's work
.form-floating {
  z-index: 0;
}
.w-125 {
  width: 125% !important;
}

.simplebar-placeholder {
  display: none !important;
}
